'use strict';

angular.module('enervexSalesappApp').controller('PropertiesCtrl', function($scope, $http, Property, $uibModal, _) {
	$scope.allProperties = [];
	$scope.offset = 0;
	$scope.limit = 10;
	function fetch() {
		Property.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(properties){
			if (properties.length > 0) {
				$scope.allProperties = $scope.allProperties.concat(properties);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.allProperties = _.sortBy($scope.allProperties, function(property){
					return property.name
				})
				$scope.applyFilter();
			}
		});
	}
	fetch();

	$scope.applyFilter = function() {
		var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;
		$scope.properties = _.filter($scope.allProperties, function(property){
			if (nameReg && !(nameReg.test(property.name) || nameReg.test(property.value) )) {
				return false;
			} else if ($scope.isConfig == 'true' && !property.isConfig) {
				return false;
			} else if ($scope.isConfig == 'false' && property.isConfig) {
				return false;
			} else {
				return true
			}
		})
	}
	$scope.delete = function(property) {
		Property.delete({
			id: property._id
		});
		_.each($scope.properties, function(o, i) {
			if (o === property) {
				$scope.properties.splice(i, 1);
			}
		});
	}
});
